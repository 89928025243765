// override for primeng dialog wrapper
.p-dialog {
  @apply
    tw-rounded-border-radius-sm
    tw-border-none;
  
  .p-dialog-content {
    @apply 
      tw-p-0
      tw-rounded-border-radius-sm;
  }
}