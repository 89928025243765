
.input-text {
  @apply
    tw-w-full
    tw-pl-spacing-14
    tw-py-spacing-10
    tw-border
    tw-border-solid
    tw-border-border-secondary
    tw-rounded-border-radius-2xs
    enabled:focus:tw-border-icon-tertiary;
}

.input-text.ng-valid, .input-text.ng-untouched {
  @apply 
    tw-border-border-secondary
    focus:tw-border-icon-tertiary;
}

// Override PrimeNG's default styling for invalid inputs
.input-text.ng-touched.ng-invalid {
  @apply
    tw-border-border-danger;
}

.p-inputtext.ng-touched.ng-invalid {
  @apply
  tw-border-border-danger
  enabled:hover:tw-border-border-danger
  enabled:focus:tw-border-border-danger;
}

.input-label {
  @apply 
    tw-text-size-14
    tw-text-text-label
    tw-pt-spacing-8;
}

.p-inputgroup {
  .p-inputgroup-addon {
    @apply
    tw-px-spacing-12
    tw-text-input-add-on-text
    tw-bg-input-add-on-default-bg
    tw-border
    tw-border-solid
    tw-border-input-add-on-border
    tw-border-r-0;
  }

  .p-inputtext {
    @apply tw-rounded-l-none;
  }
}

.p-inputtext {
  @apply
    tw-pl-spacing-14
    tw-border
    tw-border-solid
    tw-border-border-secondary
    tw-w-full
    tw-rounded-border-radius-2xs
    enabled:focus:tw-shadow-none
    enabled:focus:tw-border-icon-tertiary
    enabled:hover:tw-border-border-secondary;
}

.p-inputotp.otp-input {
  .p-inputtext {
    @apply
      tw-py-spacing-14
      tw-px-spacing-6;
  }

  .p-inputotp-input {
    @apply tw-h-[48px];
  }
}

.asterisk {
  @apply 
    tw-text-text-brand-primary
    tw-pl-spacing-2;
}

.error {
  @apply 
    tw-text-text-danger
    tw-pt-spacing-2
    tw-pr-spacing-6
    tw-pl-spacing-0
    tw-text-size-12;
}

@mixin placeholder {
  &::placeholder {@content}
  &::-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &::-webkit-input-placeholder {@content}
}

input {
  @include placeholder {
    @apply tw-text-text-disable;
  }
}
