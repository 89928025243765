/*
Adding the styles for this globally, creating a wrapper component would be complex because we will have to make the radio buttons behave as groups. (Primeng handles this on the p-radioButton natively so we can use it as is)
*/
p-radiobutton {
  .p-radiobutton {
    .p-radiobutton-box {
      @apply
      tw-bg-white
      tw-border-radio-icon-color
      tw-border-[1.5px];

      &.p-highlight:not(.p-disabled) {
        @apply
        tw-border-radio-icon-selected-color;

        .p-radiobutton-icon {
          @apply
          tw-bg-radio-icon-selected-color;
        }
      }

      &.p-disabled {
        @apply
        tw-opacity-100
        tw-border-radio-icon-disabled-color;

        &.p-highlight {
          .p-radiobutton-icon {
            @apply tw-bg-radio-icon-disabled-color;
          }
        }
      }

      &:not(.p-disabled).p-focus {
        @apply tw-shadow-none;
      }
    }

    &.p-radiobutton-disabled {
      @apply tw-cursor-default;
    }
  }

  .p-radiobutton-label {
    @apply
      tw-text-radio-label-text-color
      tw-cursor-pointer
      tw-ml-spacing-8;

    &.p-disabled {
      @apply tw-opacity-100 tw-text-radio-label-disabled-color;
    }
  }

  &:hover {
    .p-radiobutton:not(.p-radiobutton-disabled) {
      .p-radiobutton-box {
        @apply tw-border-radio-icon-hover-color;
      }
    }

    .p-radiobutton-label:not(.p-disabled) {
      @apply tw-text-radio-label-hover-color;
    }
  }
}
