.p-overlaypanel {
  @apply
    tw-border-1-px
    tw-border-solid
    tw-border-border-tertiary;

  .p-overlaypanel-content {
    @apply
      tw-p-spacing-0;
  }

  &::after, &::before {
    @apply
      tw-hidden;
  }
}