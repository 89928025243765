.p-checkbox {
  @apply
    tw-scale-90;

  .p-checkbox-box {
    @apply
      tw-border-solid
      tw-border-checkbox-icon-color
      tw-rounded-border-radius-5px
      tw-border-border-1.5
      tw-bg-transparent
      hover:tw-border-border-primary;

    .p-icon {
      @apply
        tw-h-10-px
        tw-w-10-px;
    }

    &.p-highlight {
      @apply
        tw-bg-bg-secondary
        tw-border-border-primary;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-highlight {
        @apply
          hover:tw-border-border-primary
          hover:tw-bg-bg-secondary;
      }

      &.p-focus {
        @apply
          tw-shadow-none
          tw-border-border-primary
      }
    }
  }
}

.column-name-checkbox {
  .p-checkbox-label {
    @apply
    tw-overflow-wrap-anywhere
  }
}

.p-checkbox-label {
  @apply
    tw-text-size-14
    tw-ml-spacing-8
    tw-cursor-pointer
    tw-text-checkbox-label-text;
}
