@tailwind base;

@tailwind components;

@tailwind utilities;

/* stylelint-disable no-invalid-position-at-import-rule */

@import 'primeng/resources/primeng.min.css';

@import 'primeng/resources/themes/nova/theme.css';

@import 'assets/scss/themes/fyle';

@import 'assets/scss/themes/c1';

@import '../../../libs/shared/assets/styles/fdl_styles';

@import 'assets/scss/input';

@import 'assets/scss/listbox';

@import 'assets/scss/ripple';

@import 'assets/scss/toast';

@import 'assets/scss/dynamic-dialog';

@import 'assets/scss/checkbox';

*,
html,
body {
  @apply 
    tw-font-activ-grotisk 
    tw-text-14-px
    tw-box-border
    focus:tw-outline-0
    tw-antialiased;
}

html,
body {
  @apply
    tw-h-full
    tw-w-full
}

body {
  @apply
    tw-m-0
    tw-p-0
}

a {
  @apply
    tw-no-underline
    tw-text-link-color
}

/* You can add global styles to this file, and also import other style files */

.auth-container {
  @apply 
    tw-flex
    tw-flex-col
    tw-text-text-color-3 
    tw-text-16-px 
    tw-w-full 
    tw-min-h-full 
    tw-p-16-px;

  .auth-card {
    @apply 
      tw-flex 
      tw-flex-col 
      tw-min-w-280-px 
      tw-w-468-px 
      tw-rounded-6-px 
      tw-mb-32-px 
      tw-p-32-px
      tw-bg-white;

    .title {
      @apply 
        tw-text-24-px
        tw-font-700
        tw-my-0 
        tw-leading-normal;
    }

    .sub-title {
      @apply 
        tw-text-12-px 
        tw-text-comet 
        tw-mt-10-px 
        tw-mb-6-px;
    }

  }

}

.ng-untouched, 
.ng-valid {
  @apply
    focus:tw-border-navy-blue;
}

.p-error {

  &.ng-invalid {
    @apply 
      tw-border 
      tw-border-solid 
      tw-border-fire-engine-red;
  }
}

.common-icon {
  @apply
    tw-w-20-px 
    tw-h-20-px 
    tw-mr-4-px
}

input,
select,
textarea {
  &:-webkit-autofill {
    &,
    &:active,
    &:focus,
    &:focus-visible
    &:hover {
      @apply 
        tw-shadow-white-box;
    }
  }

  &:disabled {
    @apply
      tw-bg-white-smome-light 
      tw-shadow-white-smoke-box 
      tw-border-quartz;
  }
}

// Remove spinners from Webkit browsers
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  @apply
    tw-m-0
    tw-appearance-none;
}

// Remove spinners from Firefox
input[type=number] {
  -moz-appearance: textfield !important;
}

.p-button-label {
  @apply
    tw-flex-none;
}
