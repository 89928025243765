@mixin spinner($height, $width) {

  &.p-progress-spinner {
    @apply
      tw-h-#{$height}
      tw-w-#{$width};
  }

  .p-progress-spinner-circle {
    @apply
      tw-animate-progress-spinner
  }
}

.spinner-80 {
  @include spinner('[80px]', '[80px]');
}

.spinner-60 {
  @include spinner('[60px]', '[60px]');
}

.spinner-50 {
  @include spinner('[50px]', '[50px]');
}

.spinner-40 {
  @include spinner('[40px]', '[40px]');
}

.spinner-16 {
  @include spinner('[16px]', '[16px]');
}

.spinner-14 {
  @include spinner('[14px]', '[14px]');
}
