/* stylelint-disable scss/no-dollar-variables */

// Only Add those variables in this file which are added as premitives in figma common element library, 
// Refer: https://www.figma.com/file/JAhSNaV7gZKYsQyCFZLC9w/Common-Element-library?type=design&node-id=0%3A1&mode=design&t=0FzXvulWRrAS9gc9-1
// You can view all the list of primitives by clicking on Local Variables button on right side menu, you need edit access to view this.

//opacity Primitives
$opacity-10: 0.1;
$info-opacity-10: rgba(#5c98e5, 0.1);
$warning-opacity-10: rgba(#e1af05, 0.1);
$error-opacity-10: rgba(#da1e28, 0.1);
$success-opacity-10: rgba(#24a148, 0.1);
$brand-primary-opacity-80: rgba(#0070a8, 0.8);
$brand-secondary-opacity-15: rgba(#0070a8, 0.15);
$primary-opacity-10: rgba(#161528, 0.1);

//Color Premitives
//Base Colors
$white: #ffffff;
$black: 0 0 0;

//grey shades
$grey-100: #f5f5f5;
$grey-200: #ececee;
$grey-300: #dfdfe2;
$grey-400: #a9acbc;
$grey-500: #5a5d72;
$grey-600: #414562;
$grey-700: #2c304e;
$grey-800: #161528;

//brand shades
$brand-300: #ffc2d6;
$brand-500: #0070a8;

//blue shades
$blue-10: #f6faff;
$blue-50: #fafcff;
$blue-100: #eff5fc;
$blue-200: #eef1fe;
$blue-300: #d9def8;
$blue-500: #5c98e5;
$blue-600: #0062ff;
$blue-900: #556ef2;

//yellow shades
$yellow-100: #fcf7e6;
$yellow-500: #e1af05;
$yellow-800: #d88600;

//green
$green-50: #eef8f2;
$green-100: #e9f6ed;
$green-150: #e7f3f4;
$green-200: #d9ebe1;
$green-300: #c9e1e4;
$green-450: #55b07d;
$green-500: #24a148;
$green-900: #077e8c;

//red
$red-100: #fbe8e9;
$red-200: #fce9ea;
$red-300: #f5c0c3;
$red-500: #da1e28;

//purple
$purple-200: #f4edff;
$purple-300: #dbc5ff;
$purple-500: #8e46ff;

$manate: #8c8fa6;
