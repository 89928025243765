/**
 * Styles for custom tooltip utilizing PrimeNG's tooltip component.
 * The `fdl-tooltip` class serves as the base style for our custom tooltip.
 */
.fdl-tooltip {
  @apply
    tw-animate-tooltip;

  // Styles for the tooltip text
  .p-tooltip-text {
    @apply
      tw-bg-bg-secondary
      tw-text-white
      tw-text-left
      tw-rounded-border-radius-2xs
      tw-text-size-12
      tw-py-spacing-6
      tw-px-spacing-8;
  }

  // Styles for the tooltip arrow
  &.p-tooltip-top {
    &.fdl-tooltip-align-left .p-tooltip-arrow {
      @apply tw-left-15%;
    }
    
    &.fdl-tooltip-align-right .p-tooltip-arrow {
      @apply tw-left-85%;
    }

    .p-tooltip-arrow {
      @apply tw-border-t-border-primary;
    }
  }

  &.p-tooltip-bottom {
    &.fdl-tooltip-align-left .p-tooltip-arrow {
      @apply tw-left-15%;
    }
    
    &.fdl-tooltip-align-right .p-tooltip-arrow {
      @apply tw-left-85%;
    }

    .p-tooltip-arrow {
      @apply tw-border-b-border-primary;
    }
  }

  &.p-tooltip-left {
    &.fdl-tooltip-align-left .p-tooltip-arrow {
      @apply tw-top-30%;
    }
    
    &.fdl-tooltip-align-right .p-tooltip-arrow {
      @apply tw-top-70%;
    }

    .p-tooltip-arrow {
      @apply tw-border-l-border-primary;
    }
  }

  &.p-tooltip-right {
    &.fdl-tooltip-align-left .p-tooltip-arrow {
      @apply tw-top-30%;
    }
    
    &.fdl-tooltip-align-right .p-tooltip-arrow {
      @apply tw-top-70%;
    }

    .p-tooltip-arrow {
      @apply tw-border-r-border-primary;
    }
  }
}

.password-requirements-tooltip {
  @apply
    tw-ml-4-px
    tw-min-w-[214px];

  .p-tooltip-text {
    @apply
      tw-bg-white
      tw-text-text-secondary
      tw-text-left
      tw-rounded-border-radius-2xs
      tw-text-size-12
      tw-p-spacing-16;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      @apply tw-border-r-white;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      @apply tw-border-l-white;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      @apply tw-border-t-white;
    }
  }
}

.fdl-tooltip-error {  

  .p-tooltip-text {
    @apply 
      tw-bg-toast-error-bg
      tw-p-spacing-16;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      @apply tw-border-r-toast-error-bg;
    }
  }
}