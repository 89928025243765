/* stylelint-disable scss/no-dollar-variables */

// This file is used to store all the premitives which are common to all the clients.

// Only Add those variables in this file which are added as premitives in figma common element library, 
// Refer: https://www.figma.com/file/JAhSNaV7gZKYsQyCFZLC9w/Common-Element-library?type=design&node-id=0%3A1&mode=design&t=0FzXvulWRrAS9gc9-1
// You can view all the list of premitives by clicking on Local Variables button on right side menu, you need edit access to view this.

// Size Primitives
$size-0: 0px;
$size-1: 1px;
$size-2: 2px;
$size-4: 4px;
$size-6: 6px;
$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-28: 28px;
$size-32: 32px;
$size-40: 40px;
$size-48: 48px;
$size-56: 56px;
$size-72: 72px;

//Color Premitives
//Base Colors
$white: #ffffff;
$black: 0 0 0;
